import { FunctionComponent } from "react";

export interface AdChoicesInterface {
  label: string;
}

const AdChoices: FunctionComponent<AdChoicesInterface> = () => {
  return null;
};

export default AdChoices;
