export const MikmakScript = () => {
  return null;
};

export const MikmakWidget = () => {
  return null;
};

export const MikmakButton = () => {
  return null;
};

export default MikmakScript;
